import { Box, Button, Grid, Typography } from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import React, { useState } from "react";

const Header = (props: any) => {
  const [IsMenuPressed, setIsMenuPressed] = useState(false);
  return (
    <Box
      className="flexContainer"
      sx={{
        height: "100px",
        display: "flex",
        position: "fixed",
        top: "0px",
        width: "100%",
        zIndex: 10,
        boxShadow: "rgba(0, 0, 0, 0.5) 0px 3px 8px",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#9F0000",
          p: "0px 10px",
          width: "100%",
        }}
      >
        <Grid
          container
          alignItems="center"
          sx={{
            "& .nav-item": {
              // border:"1px solid black",
              justifyContent: "center",
              color: "#FFFFFF",
              textAlign: "left",
            },
            "@media (min-width:900px)": { display: "flex" },
            "@media (max-width:899px)": { display: "none" },
            px: "50px",
          }}
        >
          <Grid item md={6}>
            <Box
              sx={{
                textAlign: "left",
                height: "100px",
                alignItems: "center",
                display: "flex",
              }}
            >
              <Button
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                <Typography
                  variant="h3"
                  className="navTitle"
                  sx={{ color: "#FFFFFF", fontSize: "2rem" }}
                >
                  RJR MARKETING
                </Typography>
              </Button>
            </Box>
          </Grid>
          <Grid
            item
            md={6}
            sx={{
              flowDirection: "row",
              display: "flex",
              justifyContent: "space-evenly",
              width: "100%",
            }}
          >
            {/* <Grid
              container
              sx={{
                alignItems: "center",
                display: "flex",
                "& img": { height: "50px" },
                "& button": { textAlign: "center", fontSize: "16px" },
                "& .nav-item": { textAlign: "center" },
              }}
            > */}
            {/* <Grid className="nav-item" item md={12} xs={12} lg={3}> */}

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Button
                sx={{
                  padding: "10px",

                  fontSize: "20px",
                  "&:hover": {
                    fontSize: "26px",
                  },
                  color: "white",
                }}
                onClick={() => {
                  props.scrollToContent("companyProfile");
                }}
              >
                {/* <Typography className="nav-link" variant="h6"> */}
                PROFILE
                {/* </Typography> */}
              </Button>
            </Box>
            {/* </Grid> */}

            {/* <Grid className="nav-item" item md={12} xs={12} lg={3}> */}
            <Box sx={{ display: "flex" }}>
              <Button
                sx={{
                  padding: "10px",

                  fontSize: "20px",
                  "&:hover": {
                    fontSize: "26px",
                  },
                  color: "white",
                }}
                onClick={() => {
                  props.scrollToContent("products");
                }}
              >
                Products
              </Button>
            </Box>

            <Box sx={{ display: "flex" }}>
              <Button
                sx={{
                  padding: "10px",

                  fontSize: "20px",
                  "&:hover": {
                    fontSize: "26px",
                  },
                  color: "white",
                }}
                onClick={() => {
                  props.scrollToContent("contact");
                }}
              >
                Contact Us
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          sx={{
            backgroundColor: "#9F0000",
            p: "20px 20px",
            "& .nav-item": {
              justifyContent: "center",
              color: "#FFFFFF",
              textAlign: "left",
            },
            height: "100%",
            "@media (min-width:900px)": { display: "none" },
            "@media (max-width:899px)": { display: "flex" },
          }}
        >
          <Grid item xs={11} lg={6}>
            <Box
              sx={{
                textAlign: "left",
                alignItems: "center",
                display: "flex",
                height: "100%",
              }}
            >
              <Button
                onClick={() => {
                  window.scrollTo(0, 0);
                  setIsMenuPressed(false);
                }}
                sx={{ textDecoration: "none" }}
              >
                <Typography variant="h4" sx={{ color: "#FFFFFF" }}>
                  RJR MARKETING
                </Typography>
              </Button>
            </Box>
          </Grid>
          <Grid item xs={1}>
            <Box>
              <Button
                sx={{ color: "white" }}
                onClick={() => {
                  setIsMenuPressed(!IsMenuPressed);
                }}
              >
                <MenuIcon />
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {IsMenuPressed
        ? true && (
            <Box
              sx={{
                backgroundColor: "#9F0000",
                p: "0px 50px",
                width: "100%",
                justifyContent: "center",
                textAlign: "center",
                "& buttonItem": "",
              }}
            >
              <Box sx={{ padding: "20px, 0px" }} className="buttonItem">
                <Button
                  className="buttonItem"
                  sx={{ padding: "20px, 0px" }}
                  onClick={() => {
                    props.scrollToContent("companyProfile");
                    setIsMenuPressed(false);
                  }}
                >
                  <Typography style={{ color: "#fff", fontSize: "24px" }}>
                    Profile
                  </Typography>
                </Button>
              </Box>
              <Box sx={{ padding: "20px, 0px" }} className="buttonItem">
                <Button
                  className="buttonItem"
                  sx={{ padding: "20px, 0px" }}
                  onClick={() => {
                    props.scrollToContent("products");
                    setIsMenuPressed(false);
                  }}
                >
                  <Typography style={{ color: "#fff", fontSize: "24px" }}>
                    Products
                  </Typography>
                </Button>
              </Box>
              <Box sx={{ padding: "20px, 0px" }} className="buttonItem">
                <Button
                  className="buttonItem"
                  sx={{ padding: "20px, 0px" }}
                  onClick={() => {
                    props.scrollToContent("contact");
                    setIsMenuPressed(false);
                  }}
                >
                  <Typography style={{ color: "#fff", fontSize: "24px" }}>
                    Contact Us
                  </Typography>
                </Button>
              </Box>
            </Box>
          )
        : null}
    </Box>
  );
};

export default Header;
