import { Box, Grid, Typography } from "@mui/material";
import Image from "mui-image";

const CompanyProfile = () => {
  return (
    <Box
      id="companyProfile"
      sx={{
        "@media (min-width:1024px)": { height: "95vh" },
        "@media (max-height:768px)": { height: "100%" },
      }}
    >
      {/* Background */}
      {/* <Box
        sx={{
          position: "absolute",
          zIndex: -1,
          overflow: "hidden",
          width: "100%",
          height: "100%",
        }}
      >
        <img
          style={{
            filter: "opacity(20%)",
            objectFit: "cover",
          }}
          src={require("../assets/background/IMG_2661.JPG")}
        />
      </Box> */}
      <Box
        sx={{
          position: "absolute",
          zIndex: -1,
          overflow: "hidden",
          width: "100%",
          height: "120%",
          filter: "opacity(20%)",
        }}
      >
        <Image
          fit="cover"
          height="100%"
          width="100%"
          duration={1500}
          src={require("../assets/background/IMG_2661.JPG")}
        />
      </Box>

      <Box
        sx={{
          "@media (min-width:768px)": { width: "500px" },
          "@media (max-width:768px)": { width: "100%" },
          float: "right",
        }}
      >
        <Box
          sx={{
            zIndex: 3,
            backgroundColor: "black",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            padding: "10px",
            paddingRight: "10px",
            height: "100%",
          }}
        >
          <Image
            wrapperClassName="imgTitle"
            src={require("../assets/businessIcon.png")}
          />
          {/* <img
            style={{
              zIndex: 2,
              width: "50px",
              objectFit: "cover",
              paddingRight: "10px",
            }}
            src={require("../assets/businessIcon.png")}
          /> */}
          <Typography className="titleTxt">COMPANY PROFILE</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          zIndex: 3,
          height: "100%",
          alignItems: "center",
          pl: "50px",
          pr: "50px",
        }}
      >
        <Grid
          container
          sx={{
            "@media (min-width:426px)": {
              height: "100%",
              width: "100%",
            },
            "@media (max-width:425px)": {
              paddingY: 4,
            },
            display: "flex",
            alignItems: "center",
            "& .bldg": {
              my: 1,
            },
          }}
        >
          <Grid
            className="item"
            item
            sm={6}
            xs={12}
            sx={{
              width: "100%",
              // justifyContent: "center",
              // textAlign: "center",
              textAlign: "-webkit-center",
            }}
          >
            {/* <Box
              sx={{
                justifyContent: "center",
                textAlign: "center",
                "@media (min-width:768px)": {
                  width: "400px",
                },
                "@media (max-width:768px)": { width: "100%" },
              }}
            > */}
            <Image
              className="bldg"
              fit="contain"
              // height="100%"
              height="60%"
              width={"60%"}
              duration={1500}
              src={require("../assets/bldg.png")}
            />
            {/* <img
              className="bldg"
              // style={{ width: "400px" }}
              src={require("../assets/bldg.png")}
            /> */}
            {/* </Box> */}
          </Grid>

          <Grid
            className="item"
            item
            sm={6}
            xs={12}
            sx={{
              "& .textPage2": {
                letterSpacing: "0px",
                textAlign: "justify",
                padding: "20px",
                "@media (min-width:768px)": { fontSize: "20px" },
                "@media (max-width:768px)": { fontSize: "14px" },
                "@media (max-width:425px)": { fontSize: "13px" },
              },
            }}
          >
            <Box sx={{}}>
              <Typography className="textPage2" variant="body2">
                RJR Marketing is founded in 1999 with its main purpose of Linen
                Manufacturing providing Linen supplies, Office Supplies,
                Electronics and IT Equipment for Government Hospitals and
                Private sectors. Over the years, the business evolved to include
                trading and providing customer's needs from originally Linens
                and Equipments to anything within the business scope for
                client's supplies.
              </Typography>
            </Box>
            <Box sx={{}}>
              <Typography className="textPage2" variant="body2">
                Currently, RJR Marketing is a one stop shop for every imaginable
                item needed by clients with extensive suppliers throughout the
                country bringing quality and affordable items for client's
                needs.
              </Typography>
            </Box>
            <Box sx={{}}>
              <Typography className="textPage2" variant="body2">
                RJR Marketing is PhilGEPS Platinum registered and is able to
                cater government transactions
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CompanyProfile;
