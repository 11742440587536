import { Box, Typography } from "@mui/material";
import Image from "mui-image";
import Footer from "./Footer";

const ContactUs = () => {
  return (
    <Box
      id="contact"
      sx={{
        zIndex: 4,
        "@media (min-width:768px)": {
          height: "83vh",
          my: 1,
        },

        backgroundColor: "#F0B5BE",
      }}
    >
      <Box id="peopleBehind" sx={{ height: "70px" }}>
        <Box
          sx={{
            "@media (min-width:768px)": { width: "500px", float: "left" },
            "@media (max-width:768px)": { width: "100%" },
            backgroundColor: "#000000",
          }}
        >
          <Box
            sx={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              padding: "10px",
            }}
          >
            <Image
              wrapperClassName="imgTitle"
              src={require("../assets/phone.png")}
            />
            <Typography className="titleTxt">CONTACT US</Typography>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          columnGap: "50px",
          justifyContent: "space-evenly",
          backgroundColor: "#F0B5BE",
          flexWrap: "wrap",
          mt: 2,
        }}
      >
        <Box
          sx={{
            boxShadow: "rgba(0, 0, 0, 0.5) 0px 4px 8px",
            "@media (max-width:1024px)": {
              height: "300px",
              width: "300px",
            },
            "@media (min-width:1024px)": { height: "400px", width: "400px" },
            textAlign: "center",
            justifyContent: "center",
            my: 2,
          }}
        >
          {/* <Typography variant="h5">Where are we located? </Typography> */}
          <iframe
            title="RJR Marketing Map"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15679.487767729912!2d122.5589113!3d10.7443516!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1f639fe21264ea56!2sRJR%20Marketing!5e0!3m2!1sen!2sph!4v1666420558993!5m2!1sen!2sph"
            width="100%"
            height="100%"
          ></iframe>
          {/* <img width={"100%"} src={require("../assets/maps.png")} /> */}
        </Box>
        {/* <Box height={"300px"} sx={{ borderRight: "3px solid black" }}></Box> */}
        <Box
          sx={{
            float: "left",
            px: 3,
            "& div": { padding: "10px 0px 10px" },
            "& .contact-text": { paddingLeft: "10px" },
            // "@media (min-width:1024px)": {
            //   "& .contact-text": { fontSize: "24px" },
            //   "& img": { width: "30px" },
            // },
            // "@media (min-width:1440px)": {
            //   "& .contact-text": { fontSize: "30px" },
            //   "& img": { width: "50px" },
            // },
            "@media (max-width:768px)": {
              "& .contact-text": { fontSize: "18px" },
              "& img": { width: "30px" },
            },
            "@media (max-width:425px)": {
              "& .contact-text": { fontSize: "14px" },
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              overflow: "hidden",
            }}
          >
            <img src={require("../assets/AddressVector.png")} />
            <Typography className="contact-text">
              Cypress Avenue, Granplains Subdivision, Quintin Salas, Jaro,
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img src={require("../assets/PhoneVector.png")} />{" "}
            <Typography className="contact-text">
              (033) 330-8014 | (63) 918-3099-150
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img src={require("../assets/EmailVector.png")} />{" "}
            <Typography className="contact-text">
              rjrmarketingiloilo@gmail.com | info@rjrmarketing.com
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img
              src={require("../assets/facebook.svg").default}
              height="64px"
            />
            <Typography className="contact-text">
              www.facebook.com/RJRMerchandising
            </Typography>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default ContactUs;
