import { Box, Typography } from "@mui/material";

const Footer = () => {
  return (
    <Box
      sx={{
        height: "60px",
        position: "sticky",
        backgroundColor: "#9F0000",
        textAlign: "center",
        color: "white",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="h6" sx={{ display: "flex" }}>
          RJR Marketing Iloilo 2022
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
