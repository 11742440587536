import "./App.css";
import Box, { BoxProps } from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Button, ListItemIcon, Select, Typography } from "@mui/material";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import React, { useState } from "react";
import ReactDOM from "react-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// @ts-ignore
import { Image } from "mui-image";
import { count } from "console";

// Pages
import Header from "./Pages/Header";
import CoverPage from "./Pages/CoverPage";
import CompanyProfile from "./Pages/CompanyProfile";
import Products from "./Pages/Products";
import CarouselButton from "./Pages/CarouselButton";
// import Page4 from "./Pages/PeopleBehind";
import ContactUs from "./Pages/ContactUs";
import Footer from "./Pages/Footer";

const scrollToContent = (elementId: string) => {
  const element = document.getElementById(elementId);
  if (element) {
    const y = element.getBoundingClientRect().top + window.pageYOffset - 100;
    window.scrollTo({ top: y, behavior: "smooth" });
  }
};

function App() {
  return (
    <Box
      sx={{
        width: "100%",
        scrollBehavior: "smooth",
        "& .imgTitle": {
          "@media (min-width:768px)": { width: "50px !important" },
          "@media (max-width:768px)": { width: "40px !important" },
          pr: 2,
        },
        "& .titleTxt": {
          color: "white",
          alignItems: "center",
          "@media (min-width:768px)": { fontSize: "32px" },
          "@media (max-width:768px)": { fontSize: "26px" },
        },
      }}
    >
      <Header scrollToContent={scrollToContent} />

      <CoverPage />
      <CompanyProfile />

      <Products />
      {/* test */}
      <ContactUs />
    </Box>
  );
}

export default App;
