import { Box, Typography } from "@mui/material";
// @ts-ignore
import { Image } from "mui-image";

const CoverPage = () => {
  return (
    <Box sx={{ height: "100vh", width: "100%", overflow: "hidden" }}>
      <Box
        sx={{
          height: "100vh",
          width: "100%",
          backgroundColor: "#F1A7B3",
          alignItems: "center",
        }}
      >
        <img
          height={"50%"}
          width={"100%"}
          style={{
            position: "absolute",
            zIndex: "1",
          }}
          src={require("../assets/triangle-upper.png")}
        />
        <img
          style={{
            position: "absolute",
            right: "0px",
            zIndex: "0",
          }}
          height={"100%"}
          src={require("../assets/triangle-side.png")}
        />

        <Box
          sx={{
            width: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            position: "relative",
            flexDirection: "column",
            zIndex: 2,
            "@media (min-width:1300px)": { height: "100%" },
            "@media (max-width:1300px)": { height: "100%" },
          }}
        >
          <Image
            height={""}
            width={"auto"}
            duration={0}
            style={{}}
            src={require("../assets/rjrmarketing.png")}
          ></Image>
          <Box sx={{ "& h4": {} }}>
            <Typography variant="h3">
              <b>General Merchandising | Trading</b>
            </Typography>
            <Typography variant="h5">
              "<i>Quality Products and Service Delivered at Reasonable Price</i>
              "
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CoverPage;
