import { Typography, Box } from "@mui/material";
import CarouselButton from "./CarouselButton";
import { Image } from "mui-image";
const Products = () => {
  return (
    <Box
      sx={
        {
          // backgroundColor: "#F1A7B3",
          // boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
        }
      }
      id="products"
    >
      <Box
        sx={{
          "@media (min-width:768px)": { width: "500px" },
          "@media (max-width:768px)": { width: "100%" },
        }}
      >
        <Box
          sx={{
            zIndex: 3,
            backgroundColor: "black",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            padding: "10px",

            objectFit: "cover",
            paddingRight: "10px",
          }}
        >
          <Image
            wrapperClassName="imgTitle"
            src={require("../assets/sales.png")}
          />

          <Typography className="titleTxt">PRODUCTS AND SERVICES</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          py: 6,
          display: "flex",
          flexFlow: "wrap",
          justifyContent: "space-evenly",
          textAlign: "center",
          fontSize: "20px",
          rowGap: 3,
          backgroundColor: "#F1A7B3",
          "& .svgProductContainer": {
            px: 5,
            paddingBottom: 5,
            backgroundColor: "#F2EAE4",
            boxShadow:
              "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",

            width: "8em",
            justifyContent: "center",
          },
          "& .productTitle": {
            height: "30px",
            lineHeight: "1.15rem",
          },
          "& .cardImg": {
            height: "120px !important",
            py: 2,
          },
          "& .cardDescDetails": {
            py: 0.5,
            fontSize: "0.9rem",
          },
          "& .cardDescContainer": {
            pt: 1,
          },
        }}
      >
        <Box className="svgProductContainer">
          {/* <img
              style={{ height: "100px" }}
              src={require("../assets/isometric-linen.svg").default}
              alt="mySvgImage"
            /> */}
          <Image
            fit="contain"
            wrapperClassName="cardImg"
            duration={1500}
            src={require("../assets/isometric-linen.svg").default}
          />
          <Box
            sx={{ paddingTop: "10px", borderBottom: "3px solid gray" }}
          ></Box>
          <Typography className="productTitle">LINEN SUPPLIES</Typography>

          <Box className="cardDescContainer">
            <Typography className="cardDescDetails">
              Customized Household and Office Curtains
            </Typography>
            <Typography className="cardDescDetails">
              Fabric printing Services
            </Typography>
            <Typography className="cardDescDetails">
              Hospital Gowns and Cadaver Bags
            </Typography>
          </Box>
        </Box>
        <Box className="svgProductContainer">
          {/* {<img
            style={{ height: "120px" }}
            src={require("../assets/isometric-electronics.svg")}
          />} */}
          {/* <ElectronicsSVG /> */}
          {/* <img
              style={{ height: "100px" }}
              src={require("../assets/isometric-electronics.svg").default}
              alt="mySvgImage"
            /> */}
          <Image
            fit="contain"
            wrapperClassName="cardImg"
            duration={1500}
            src={require("../assets/isometric-electronics.svg").default}
          />
          <Box
            sx={{ paddingTop: "10px", borderBottom: "3px solid gray" }}
          ></Box>
          <Typography className="productTitle">ELECTRONICS</Typography>
          <Box className="cardDescContainer">
            <Typography className="cardDescDetails">
              Heating Elements
            </Typography>
            <Typography className="cardDescDetails">
              PA Speakers and Microphones
            </Typography>
          </Box>
        </Box>
        <Box className="svgProductContainer">
          {/* <img
              style={{ height: "100px" }}
              src={require("../assets/isometric-office-supplies.svg").default}
            /> */}
          <Image
            fit="contain"
            wrapperClassName="cardImg"
            duration={1500}
            src={require("../assets/isometric-office-supplies.svg").default}
          />
          <Box
            sx={{ paddingTop: "10px", borderBottom: "3px solid gray" }}
          ></Box>
          <Typography className="productTitle">OFFICE SUPPLIES</Typography>

          <Box className="cardDescContainer">
            <Typography className="cardDescDetails">
              Papers, Folders, Pens
            </Typography>
            <Typography className="cardDescDetails">Etc.</Typography>
          </Box>
        </Box>
        <Box className="svgProductContainer">
          {/* <img
              style={{ height: "100px" }}
              src={require("../assets/isometric-office-supplies.svg").default}
            /> */}
          <Image
            fit="contain"
            wrapperClassName="cardImg"
            duration={1500}
            src={require("../assets/isometric-office-supplies.svg").default}
          />
          <Box
            sx={{ paddingTop: "10px", borderBottom: "3px solid gray" }}
          ></Box>
          <Typography className="productTitle">OFFICE FURNITURES</Typography>
          <Box className="cardDescContainer">
            <Typography className="cardDescDetails">
              Computer chairs and tables
            </Typography>
            <Typography className="cardDescDetails">
              Office Filing Cabinets
            </Typography>
          </Box>
        </Box>
        <Box className="svgProductContainer">
          {/* <img
              style={{ height: "100px" }}
              src={
                require("../assets/isometric-it-computer-hardware.svg").default
              }
            /> */}
          <Image
            fit="contain"
            wrapperClassName="cardImg"
            duration={1500}
            src={
              require("../assets/isometric-it-computer-hardware.svg").default
            }
          />
          <Box
            sx={{ paddingTop: "10px", borderBottom: "3px solid gray" }}
          ></Box>
          <Typography className="productTitle">IT EQUIPMENTS</Typography>
          <Box className="cardDescContainer">
            <Typography className="cardDescDetails">CCTV</Typography>
            <Typography className="cardDescDetails">
              Computer Accessories and Peripherals
            </Typography>
            <Typography className="cardDescDetails">
              Networking Accessories
            </Typography>
            <Typography className="cardDescDetails">
              Laptops and Pre-build Desktops
            </Typography>
            <Typography className="cardDescDetails">
              Printers, Toners and Inks
            </Typography>
          </Box>
        </Box>
        <Box className="svgProductContainer">
          {/* <img
              style={{ height: "100px" }}
              src={
                require("../assets/isometric-it-computer-hardware.svg").default
              }
            /> */}
          <Image
            fit="contain"
            wrapperClassName="cardImg"
            duration={1500}
            src={require("../assets/isometric-medical-supplies.svg").default}
          />
          <Box
            sx={{ paddingTop: "10px", borderBottom: "3px solid gray" }}
          ></Box>
          <Typography className="productTitle">MEDICAL SUPPLIES</Typography>
          <Box className="cardDescContainer">
            <Typography className="cardDescDetails">
              Facemasks, Thermometers, Gloves
            </Typography>
            <Typography className="cardDescDetails">
              Laboratory Apparatus and Appliances
            </Typography>
          </Box>
        </Box>
        <Box className="svgProductContainer">
          {/* <img
              style={{ height: "100px" }}
              src={
                require("../assets/isometric-it-computer-hardware.svg").default
              }
            /> */}
          <Image
            fit="contain"
            wrapperClassName="cardImg"
            duration={1500}
            src={require("../assets/isometric-solarpanel-inverter.svg").default}
          />
          <Box
            sx={{ paddingTop: "10px", borderBottom: "3px solid gray" }}
          ></Box>
          <Typography className="productTitle">
            SOLAR PANELS AND INVERTERS
          </Typography>
          <Box className="cardDescContainer">
            <Typography className="cardDescDetails">
              Low and High Capacity UPS and Solar Inverters
            </Typography>
            <Typography className="cardDescDetails">
              Grid Tie, Hybrid Setup
            </Typography>
            <Typography className="cardDescDetails">
              Deep Cycle Solar Batteries
            </Typography>
          </Box>
        </Box>
      </Box>

      <CarouselButton />
    </Box>
  );
};

export default Products;
