import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { Image } from "mui-image";
const imageArray = [
  //   {
  //
  //     title: "Linen Supplies",
  //     // image: require("../assets/products/linen/"),
  //   },
  {
    title: "Electronics",
    image: require("../assets/products/electronics/18.jpg"),
  },
  {
    title: "Computer Accessories",
    image: require("../assets/products/computerAccessories/ComputerAccessories.png"),
  },
  {
    title: "Computer Parts",
    image: require("../assets/products/computerParts/ComputerParts.png"),
  },
  {
    title: "Office Supplies",
    image: require("../assets/products/officeSupplies/officeSupplies.png"),
  },
  {
    title: "Medical Supplies",
    image: require("../assets/products/medicalSupplies/medicalSupplies.png"),
  },
  {
    title: "Network Equipments",
    image: require("../assets/products/networkingEquipments/networkEquipments.png"),
  },
];

const CarouselButton = (props: any) => {
  const [count, setCount] = useState<number>(0);
  return (
    <Box
      sx={{
        "@media (min-width:900px)": { height: "50vh" },
        "@media (max-height:899px)": { height: "100%" },
        backgroundColor: "transparent",
        alignItems: "center",
        px: "20px",
        pt: 3,
        pb: 6,
        // color: "#000",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          maxWidth: "95vw",
          width: "100%",
          height: "100%",
          overflow: "hidden",
          zIndex: -1,
          "& .cardImg": { opacity: 0.07, mt: -20 },
        }}
      >
        <Image
          fit="contain"
          wrapperClassName="cardImg"
          duration={1500}
          src={require("../assets/products/0.png")}
        />
      </Box>
      <Grid container sx={{ px: 6, height: "100%" }}>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Typography variant="h4" sx={{ py: 3 }}>
            Product Catalog
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          sx={{
            "@media (min-width:768px)": {
              px: 6,
            },
            "@media (max-width:768px)": {
              px: 0,
            },
          }}
        >
          <Box
            sx={{
              boxShadow: "rgba(0, 0, 0, 0.5) 0px 3px 8px",
              backgroundColor: "white",
              "@media (min-width:768px)": {
                height: "400px",
              },
            }}
          >
            <Box
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              {count <= imageArray.length && count > 0 ? (
                <Button
                  sx={{ float: "right" }}
                  onClick={() => setCount(count - 1)}
                >
                  <ArrowBackIosNewIcon
                    style={{
                      fontSize: "60px",
                      color: "black",
                    }}
                  />
                </Button>
              ) : (
                <Box sx={{ width: "90px" }}> </Box>
              )}
              <Box
                id="image-carousel"
                sx={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  "@media (min-width:768px)": {
                    height: "300px",
                    width: "400px",
                  },
                  "@media (max-width:768px)": {
                    height: "250px",
                    width: "250px",
                  },
                }}
              >
                <Image
                  fit="contain"
                  wrapperClassName="cardImg"
                  duration={1500}
                  src={imageArray[count].image}
                />
                <Typography
                  variant="h5"
                  sx={{ py: 2, textAlign: "center", color: "" }}
                >
                  <Typography sx={{ color: "black" }}>
                    {count + 1}/{imageArray.length}
                  </Typography>
                  {imageArray[count].title}
                </Typography>
              </Box>
              {count >= 0 && count < imageArray.length - 1 ? (
                <Button
                  sx={{ float: "left" }}
                  onClick={() => setCount(count + 1)}
                >
                  <ArrowForwardIosIcon
                    style={{
                      fontSize: "60px",
                      color: "black",
                    }}
                  />
                </Button>
              ) : (
                <Box sx={{ width: "90px" }}> </Box>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            sx={{
              py: 3,
              fontSize: "1.2rem",
              textAlign: "justify",
            }}
          >
            We also cater specific order basis products and goods locally or
            internationally sourced. We will handle all the hassles of shipping
            and handling. Feel free to visit our office or contact us for
            further details.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CarouselButton;
